import React from 'react'
import { Rating } from 'react-simple-star-rating'
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle';

import Header from '../../../Components/Header/header';
import '../view-package/demo-viewpackage.css'
import MultipleSlider from '../view-package/slider/ViewPackage-multipleSlider'

import packageimg1 from '../packagelist/img/service-3 1.png'
import packageimg2 from '../packagelist/img/service-1 1.png'
import packageimg3 from '../packagelist/img/service-2 1.png'
import packageimg4 from '../packagelist/img/Image 2 1.png'
import SingleImageSlider from './slider/viewpackage-single-image-slider';
import ViewpackageTabbedDetails from './tabs/viewpackage-tabbed-details';
import PackageSlider from '../packagelist/slider/slider';

export default function DemoViewpackage() {

    //Star Rating
    const [rating, setRating] = useState(0)
    const location = useLocation();
    const {item} = location.state;
    // console.log('asdasdasd',item._id);
    // Catch Rating value
    const handleRating = (rate: number) => {
      setRating(rate)
    }
    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = (value: number, index: number) => console.log(value, index)

  return (
    <div>
      <Header/>
      <div className="viewpackage-main-container container mb-5">
        <div className="viewpackage-hero-container container">
          <div className="ViewPackage-PackageTitle container-fluid d-flex">
            <div className="col-6">

            </div>
            <div className="col-6 PackageTitle-wrapper mt-4">
              <h6>Rate Your Yathra</h6>
              <h2>{item.pkg_name}</h2>
              <div className="viewpackage-rating-container">
                <Rating
                  onClick={handleRating}
                  onPointerEnter={onPointerEnter}
                  onPointerLeave={onPointerLeave}
                  onPointerMove={onPointerMove}
                  size={35}
                  /* Available Props */
                />
              </div>
            </div>
          </div>
          <div className="ViewPackage-PackageDescription container-fluid">
            <div className="col-6 viewpackage-description-left container">
              <SingleImageSlider/>
              <Link to='/booking' state={{pkg_id:item._id}}><button className='btn bookbtn px-4'>Book Your Yatra</button></Link>
            </div>
            <div className="col-6 viewpackage-description-right">
              <h4>{item.pkg_name}</h4>
                <p>
                {item.description}
                </p>
            </div>
          </div>
          
          <div className="viewpackage-multiple-slider-container mt-5">
            <MultipleSlider/>
          </div>

        </div>
        
        <div className="container">
          <ViewpackageTabbedDetails/>
        </div>

        {/* <section className="hero container-fluid section-margin">
          <div className="container-fluid d-flex justify-content-center align-items-center flex-column mt-5">
            <div className="container mb-5 d-flex flex-column justify-content-center align-items-center">
              <h6>Look at the Trending</h6>
              <h2>Look At The Trending</h2>
            </div>

            <div className="d-flex container-fluid">
              <div className="col-4 d-flex">
                <div className="package-image d-flex flex-column justify-content-center">
                  <img src ={packageimg1} alt=''/>
                </div>
                <div className="package-details mr-5 py-4">
                  <h2 className="packagename">
                    The wind of kaashi
                  </h2>
                  <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                    incididunt ut laborea. 
                  </p>
                  <Rating
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    onPointerMove={onPointerMove}
                    size={27}
                  />
                  <br />
                  <button className='btn explorebtn px-4'>Explore</button>
                </div>
              </div>
              
              <div className="col-4 d-flex">
                <div className="package-image d-flex flex-column justify-content-center">
                  <img src ={packageimg2} alt=''/>
                </div>
                <div className="package-details mr-5 py-4">
                  <h2 className="packagename">
                    The wind of kaashi
                  </h2>
                  <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                    incididunt ut laborea. 
                  </p>
                  <Rating
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    onPointerMove={onPointerMove}
                    size={27}
                  />
                  <br />
                  <button className='btn explorebtn px-4'>Explore</button>
                </div>
              </div>

              <div className="col-4 d-flex">
                <div className="package-image d-flex flex-column justify-content-center">
                  <img src ={packageimg3} alt=''/>
                </div>
                <div className="package-details mr-5 py-4">
                  <h2 className="packagename">
                    The wind of kaashi
                  </h2>
                  <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                    incididunt ut laborea. 
                  </p>
                  <Rating
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    onPointerMove={onPointerMove}
                    size={27}
                  />
                  <br />
                  <button className='btn explorebtn px-4'>Explore</button>
                </div>
              </div>
            </div>

          </div>
        </section> */}

        {/* <section className='section-margin'>
        <div className="container-fluid d-flex">
          <div className="col-lg-12 d-flex">
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={packageimg4} alt="" />
            </div>
            <div className="col-lg-6 d-flex">
              <PackageSlider/>
            </div>
          </div>
        </div>
        </section> */}

        <section className="category section-margin">
          <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
              <div className="container mx-auto d-flex flex-column justify-content-center align-items-center">
                <h6>Whats trending in teerthayatra</h6>
                <h2>Category heading Here</h2>
              </div>

                <div className="viewpackageCategory col-lg-12 col-gap d-flex justify-content-center align-items-center">

                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="destinationPackageImage">
                      <img src ={packageimg2} alt=''/>
                    </div>
                    <div className="destinationPackageDetails">
                      <div className="container">
                        <h2 className="packagename">
                            The wind of kaashi
                        </h2>
                        <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                          incididunt ut laborea. 
                        </p>
                        <Rating
                          onClick={handleRating}
                          onPointerEnter={onPointerEnter}
                          onPointerLeave={onPointerLeave}
                          onPointerMove={onPointerMove}
                          size={27}
                        />
                        <br />
                        <button className='btn explorebtn px-4'>Explore</button>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="destinationPackageImage">
                      <img src ={packageimg3} alt=''/>
                    </div>
                    <div className="destinationPackageDetails">
                      <div className="container">
                        <h2 className="packagename">
                            The wind of kaashi
                        </h2>
                        <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                          incididunt ut laborea. 
                        </p>
                        <Rating
                          onClick={handleRating}
                          onPointerEnter={onPointerEnter}
                          onPointerLeave={onPointerLeave}
                          onPointerMove={onPointerMove}
                          size={27}
                        />
                        <br />
                        <button className='btn explorebtn px-4'>Explore</button>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="destinationPackageImage">
                      <img src ={packageimg1} alt=''/>
                    </div>
                    <div className="destinationPackageDetails">
                      <div className="container">
                        <h2 className="packagename">
                            The wind of kaashi
                        </h2>
                        <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                          incididunt ut laborea. 
                        </p>
                        <Rating
                          onClick={handleRating}
                          onPointerEnter={onPointerEnter}
                          onPointerLeave={onPointerLeave}
                          onPointerMove={onPointerMove}
                          size={27}
                        />
                        <br />
                        <button className='btn explorebtn px-4'>Explore</button>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="destinationPackageImage">
                      <img src ={packageimg2} alt=''/>
                    </div>
                    <div className="destinationPackageDetails">
                      <div className="container">
                        <h2 className="packagename">
                            The wind of kaashi
                        </h2>
                        <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                          incididunt ut laborea. 
                        </p>
                        <Rating
                          onClick={handleRating}
                          onPointerEnter={onPointerEnter}
                          onPointerLeave={onPointerLeave}
                          onPointerMove={onPointerMove}
                          size={27}
                        />
                        <br />
                        <button className='btn explorebtn px-4'>Explore</button>
                      </div>
                    </div>
                  </div>

                </div>
          </div>
      </section>
      </div>
    </div>
  )
}
