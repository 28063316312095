import React from 'react'
import Header from '../../Components/Header/header'
import '../yathrapackages/yathrapackagesClient.css'
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';

export default function YathapackagesTimeline() {
  return (
    <div>
<Header/>

    <section className="timeline-section">
        <div className="timeline-items">
            <div className="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-date">Day 1</div>
                <div className="timeline-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <h3>Travel Benefits</h3>
                    <ul>
                        <li>Arrival transfer</li>
                        <li>Sightseeing Tour</li>
                        <li>Exclusive Rides</li>
                        <li>Departure Transfer</li>
                    </ul>
                    <h3>Hotel Benefits</h3>
                    <div className="timeline-iconbox">
                        <i className='timeline-icon'><FreeBreakfastIcon Add sx={{ fontSize: "70px" }} /><br/>
                        Breakfast
                        </i>
                        <i className='timeline-icon'><LunchDiningIcon Add sx={{ fontSize: "70px" }} /><br/>
                        Lunch
                        </i>
                        <i className='timeline-icon'><DinnerDiningIcon Add sx={{ fontSize: "70px" }} /><br/>
                        Dinner
                        </i>
                    </div>
                </div>
            </div>
            
            <div className="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-date">Day 2</div>
                <div className="timeline-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <h3>Travel Benefits</h3>
                    <ul>
                        <li>Arrival transfer</li>
                        <li>Sightseeing Tour</li>
                        <li>Exclusive Rides</li>
                        <li>Departure Transfer</li>
                    </ul>
                    <h3>Hotel Benefits</h3>
                    <div className="timeline-iconbox">
                        <i className='timeline-icon'><FreeBreakfastIcon Add sx={{ fontSize: "70px" }} /><br/>
                        Breakfast
                        </i>
                        <i className='timeline-icon'><LunchDiningIcon Add sx={{ fontSize: "70px" }} /><br/>
                        Lunch
                        </i>
                        <i className='timeline-icon'><DinnerDiningIcon Add sx={{ fontSize: "70px" }} /><br/>
                        Dinner
                        </i>
                    </div>
                </div>
            </div>

            <div className="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-date">Day 3</div>
                <div className="timeline-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <h3>Travel Benefits</h3>
                    <ul>
                        <li>Arrival transfer</li>
                        <li>Sightseeing Tour</li>
                        <li>Exclusive Rides</li>
                        <li>Departure Transfer</li>
                    </ul>
                    <h3>Hotel Benefits</h3>
                    <div className="timeline-iconbox">
                        <i className='timeline-icon'><FreeBreakfastIcon Add sx={{ fontSize: "70px" }} /><br/>
                        Breakfast
                        </i>
                        <i className='timeline-icon'><LunchDiningIcon Add sx={{ fontSize: "70px" }} /><br/>
                        Lunch
                        </i>
                        <i className='timeline-icon'><DinnerDiningIcon Add sx={{ fontSize: "70px" }} /><br/>
                        Dinner
                        </i>
                    </div>
                </div>
            </div>

            <div className="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-date">Day 4</div>
                <div className="timeline-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <h3>Travel Benefits</h3>
                    <ul>
                        <li>Arrival transfer</li>
                        <li>Sightseeing Tour</li>
                        <li>Exclusive Rides</li>
                        <li>Departure Transfer</li>
                    </ul>
                    <h3>Hotel Benefits</h3>
                    <div className="timeline-iconbox">
                        <i className='timeline-icon'><FreeBreakfastIcon Add sx={{ fontSize: "70px" }} /><br/>
                        Breakfast
                        </i>
                        <i className='timeline-icon'><LunchDiningIcon Add sx={{ fontSize: "70px" }} /><br/>
                        Lunch
                        </i>
                        <i className='timeline-icon'><DinnerDiningIcon Add sx={{ fontSize: "70px" }} /><br/>
                        Dinner
                        </i>
                    </div>
                </div>
            </div>

            <div className="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-date">Day 5</div>
                <div className="timeline-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <h3>Travel Benefits</h3>
                    <ul>
                        <li>Arrival transfer</li>
                        <li>Sightseeing Tour</li>
                        <li>Exclusive Rides</li>
                        <li>Departure Transfer</li>
                    </ul>
                    <h3>Hotel Benefits</h3>
                    <div className="timeline-iconbox">
                        <i className='timeline-icon'><FreeBreakfastIcon Add sx={{ fontSize: "70px" }} /><br/>
                        Breakfast
                        </i>
                        <i className='timeline-icon'><LunchDiningIcon Add sx={{ fontSize: "70px" }} /><br/>
                        Lunch
                        </i>
                        <i className='timeline-icon'><DinnerDiningIcon Add sx={{ fontSize: "70px" }} /><br/>
                        Dinner
                        </i>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}
