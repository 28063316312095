import React, { useRef, useState } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import packageimg1 from '../img/service-3 1.png'
import packageimg2 from '../img/service-1 1.png'
import packageimg3 from '../img/service-2 1.png'

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import '../slider/SingleSlider.css'

// import required modules
import { Navigation } from "swiper";

export default function SingleImageSlider() {


  return (
    <div className="viewpackage-slider-main-container">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Navigation]}
        className="mySingleSwiper"
      >
        <SwiperSlide className="Slider-container">
          <img src={packageimg1} alt=""  className="SingleSLiderImg"/>
        </SwiperSlide>

        <SwiperSlide className="Slider-container">
          <img src={packageimg2} alt=""  className="SingleSLiderImg"/>
        </SwiperSlide>

        <SwiperSlide className="Slider-container">
          <img src={packageimg3} alt=""  className="SingleSLiderImg"/>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
