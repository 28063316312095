import React, { useEffect, useState } from 'react'
import './gallery.css'

//import images
import packageimg4 from '../../Components/assets/images/home1.jpg'
import packageimg5 from '../../Components/assets/images/home2.jpg'
import packageimg1 from '../../Components/assets/images/home3.jpg'
import packageimg2 from '../../Components/assets/images/home4.jpg'
import packageimg3 from '../../Components/assets/images/home5.jpg'
import Header from '../../Components/Header/header'
import http from '../../axios'

export default function Gallery() {

  const[images,setImg] = useState('');

  useEffect(()=>{

    let response = http.get('user/gallery/all')
    .then( response => {

      var data = response.data.data[0];
      if(data){
        setImg(data.gallery_images);
      }

      console.log(data);
    });

  },[0])



  return (
    <div>
    <Header/>
    <section className='gallery_MainSection'>
        {images ? 
          images.map((item,index)=>{
            return <img key={index} src={process.env.REACT_APP_baseURL+item}/>
          }) : ''
        }
    </section>
    </div>
  )
}
