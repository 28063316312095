import React, { useEffect, useState } from 'react'
import './header.css'
import Logo from '../assets/logo/logo.png'
import menuItem from '../Menu/Menu'
import { Col,Row } from 'react-bootstrap'
import {NavLink, useNavigate} from "react-router-dom";
import {BsList} from "react-icons/bs";

function Header() {

  const [isNavExpanded, setIsNavExpanded] = useState(false);
  // console.log(isNavExpanded ? 'asdasd' : 'true');
  const isLoggedIn = localStorage.getItem('token');
  const navigate = useNavigate();


  const logOut = () =>{
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    navigate('/');
  }

  useEffect( () =>{
  })

  return (
    <Col className='header-container'>
      <Row className='logo'>
        <Col className='hamburger' ><BsList size={40} onClick={() => { setIsNavExpanded(!isNavExpanded) }}/></Col>
        <Col className='abcd'><img src={Logo} alt='logo'></img></Col>
      </Row>
      <Row className={ isNavExpanded ? 'menuitem-active' : 'menuitem'}>
            <ul>

                {
                  menuItem.map((item,index)=>{
                    return(
                      <li key={index}><NavLink className={(navData) => (navData.isActive ? "is-active" : 'navmenu')} to={item.path}>{item.title}</NavLink> </li>                  
                      );
                    })
                }        
                <li><NavLink className={(navData) => (navData.isActive ? "is-active" : 'navmenu')} to='admin_login'>admin login</NavLink> </li> 
                {isLoggedIn ? <li> <NavLink className='navmenu' to='/' onClick={logOut}>Logout</NavLink></li> : <li><NavLink className='navmenu' to='/login'>Login/Signup</NavLink> </li>}
            </ul>        
      </Row>
      
    </Col>
  )
}

export default Header;