import React, { useContext, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import http from '../../../../axios';
import { FaEdit,FaTrashAlt } from 'react-icons/fa';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Yathrapackagesnew from './yathrapackagesnew';

function ManagePackages() {

  const [packages,setPackages] = useState([]);
  const navigate = useNavigate();

  useEffect( () =>{


        let response = http.get('admin/package/all')
      
      .then( response => {

        console.log('data',response.data.data);
        setPackages(response.data.data);
      });
      

  },[0])

  return (

    <div>
        <h3>Manage Yathras</h3>
        <br />
        <Table striped bordered hover style={{width: "calc(100% - 100px)"}}>
        <thead>
            <tr>
            <th>#</th>
            <th>Package Name</th>
            <th>Seats</th>
            <th>Price</th>
            <th>Booking Starts</th>
            <th>Booking Closes</th>
            <th>Yathra Date</th>
            <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {/* <tr> */}
            {
              packages.map((item,index) =>
               
              <tr key={index}>
                <td>{index+1}</td>
                <td>{item.pkg_name}</td>
                <td>{item.seats}</td>
                <td>{item.price}</td>
                <td>{item.start_date}</td>
                <td>{item.close_date}</td>
                <td>{item.trip_date}</td>
                <td><Link to="/dashboard/yathra_packages" state={{data : item}}><button className='edit_btn'><span className='icon'><FaEdit/></span>Edit</button></Link>
                <button className='delete_btn'><span className='icon'><FaTrashAlt/></span>Delete</button>
                </td>
              </tr>
                  
              
              )
            }
                    
        </tbody>
        </Table>
    </div>

  )
}

export default ManagePackages