import React from 'react'
import './contact-form.css'

function ContactForm() {
  return (
    <div className='contact-form'>

        <table>
            <tbody>
                <tr>
                    <td><label>Email</label></td>
                    <td><input type='text'></input></td>
                </tr>
                <tr>
                    <td><label>Phone Number</label></td>
                    <td><input type='text'></input></td>
                </tr>
                <tr>
                    <td><label>Message</label></td>
                    <td><input type='texarea'></input></td>
                </tr>

                <tr>
                    <td><input type='submit'></input></td>
                </tr>
            </tbody>
        </table>
                
      
    </div>
  )
}

export default ContactForm