import React, { useEffect, useState } from 'react'
import '../yathra packages/newyathraform.css'
import {getAllCountries, getStateByCountry, getCitiesByStates} from '../../../../Components/country-state-city/csc';
import RichTextEditor from './texteditor';
import TextEditor2 from './texteditor2';
import http from '../../../../axios';
import { useLocation, useNavigate } from 'react-router-dom';
import ImageHolder from '../../../../Assets/images/image_holder.png'

export default function Yathrapackagesnew() {


  const [pkg_data,setData] = useState({pkg_name:'',description:'',long_description:'',start_date:'',close_date:'',trip_date:'',seats:'',price:'',pkg_image:''});
  const [preview,setPreview] = useState('');
  const navigate = useNavigate();

  const location = useLocation();
  const data = location.state ? location.state : false;
  useEffect(()=>{
  
    if(data){
      setData({
        pkg_name: data.data.pkg_name,
        description: data.data.description,
        start_date: data.data.start_date,
        close_date: data.data.close_date,
        trip_date: data.data.trip_date,
        seats: data.data.seats,
        price: data.data.price,
        pkg_image: data.data.pkg_image,
      });
  
    }
  },[0])

  const handleChange  = (e) =>{

    if(e.target.name == 'cover_photo'){

      setPreview(URL.createObjectURL(e.target.files[0]));
      setData(prevState => ({
        ...prevState,
        [e.target.name] : e.target.files[0]
      }))
    }else{

    
      setData(prevState => ({
        ...prevState,
        [e.target.name] : e.target.value
      }))

    }
    
}

  const richtextVal = (e) =>{

    setData(prevState => ({
      ...prevState,
      ['long_description'] : e
    }))


  }

  const addPackage = async() => {

    let formData = new FormData();    //formdata object

    formData.append('pkg_name', pkg_data.pkg_name);
    formData.append('description', pkg_data.description);
    formData.append('long_description', pkg_data.long_description);
    formData.append('start_date', pkg_data.start_date);
    formData.append('close_date', pkg_data.close_date);
    formData.append('trip_date', pkg_data.trip_date);
    formData.append('seats', pkg_data.seats);
    formData.append('price', pkg_data.price);
    formData.append('pkg_image', pkg_data.cover_photo);


    let response = await http.post('admin/package/add',formData,{
      headers:{
        'Authorization': `Bearer ${localStorage.getItem('admin_token')}`
      }
    })
    .then( response => {

      navigate('/dashboard/manage-yathras');

    });

  }

  const updatePackage = async() => {

    let formData = new FormData();    //formdata object

    formData.append('_id', data.data._id);
    formData.append('pkg_name', pkg_data.pkg_name);
    formData.append('description', pkg_data.description);
    formData.append('start_date', pkg_data.start_date);
    formData.append('close_date', pkg_data.close_date);
    formData.append('trip_date', pkg_data.trip_date);
    formData.append('seats', pkg_data.seats);
    formData.append('price', pkg_data.price);
    formData.append('pkg_image', pkg_data.cover_photo);


    let response = await http.post('admin/package/edit',formData,{
      headers:{
        'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
      }
    })
    
    .then( response => {
      navigate('/dashboard/manage-yathras');

    });

  }


  return (
        
            <div className="col-md-12 mb-5">
                <form onSubmit={(event) => event.preventDefault()} encType='multipart/form-data' method='POST' class="yathraform">
                {/* <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link yathra-select active" id="pills-about-tab" data-bs-toggle="pill" data-bs-target="#pills-about" type="button" role="tab" aria-controls="pills-about" aria-selected="true">Details</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link yathra-select" id="pills-plans-tab" data-bs-toggle="pill" data-bs-target="#pills-plans" type="button" role="tab" aria-controls="pills-plans" aria-selected="false">Plans</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link yathra-select" id="pills-hotels-tab" data-bs-toggle="pill" data-bs-target="#pills-hotels" type="button" role="tab" aria-controls="pills-hotels" aria-selected="false">Hotels</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link yathra-select" id="pills-transportation-tab" data-bs-toggle="pill" data-bs-target="#pills-transportation" type="button" role="tab" aria-controls="pills-transportation" aria-selected="false">Transportation</button>
                    </li>
                  </ul> */}
                  <div className="tab-content" id="pills-tabContent">
                    {/* <div class="tab-pane fade show active mt-4 yathra-panel" id="pills-about" role="tabpanel" aria-labelledby="pills-about-tab" tabindex="0"> */}
                        <label for="title">
                            Yatra Title
                        </label>
                        <input type="text" name="pkg_name" id="title" placeholder="Yatra Title" value={pkg_data.pkg_name} onChange={handleChange} class="form-control yathra-form-input" />
                        <img className='fit_image' name='preview' src={preview ? preview : ImageHolder} alt="" width={100} height={100}/>
                        <label for="title">
                            Yatra Cover Photo
                        </label>
                        {data ? 
                        <>
                          <img className='fit_image' name='preview' src={process.env.REACT_APP_baseURL+pkg_data.pkg_image} alt="" width={100} height={100}/>
                          <label for="title">
                              Yatra Cover Photo
                          </label>
                          </>: ''
                        }
                        <input type="file" name="cover_photo" id="title" placeholder="Yatra Cover Photo" onChange={handleChange} class="form-control yathra-form-input" />
                        <label for="about">
                            Yatra Description
                        </label>
                        <textarea name="description" id="about" cols="30" rows="10" value={pkg_data.description} onChange={handleChange} class="form-control yathra-form-input"></textarea>
                        <label for="destination">
                            Long description
                        </label>
                        <div>
                        <RichTextEditor width={500} getValue={richtextVal} />
                        </div>
                        <label for="destination">
                            Price
                        </label>
                        <input type="text" name="price" placeholder="Price" value={pkg_data.price} onChange={handleChange} class="form-control yathra-form-input"/>
                        <label for="destination">
                            Booking Starts
                        </label>
                        <input type="date" name="start_date" placeholder="Start Date" value={pkg_data.start_date} onChange={handleChange} class="form-control yathra-form-input"/>
                        <label for="place">
                            Booking Ends
                        </label>
                        <input type="date" name="close_date" placeholder="Close Date" value={pkg_data.close_date}  onChange={handleChange} class="form-control yathra-form-input"/>
                        <label for="district">
                            Yathra Date
                        </label>
                        <input type="date" name="trip_date" placeholder="Yathra Date" value={pkg_data.trip_date} onChange={handleChange} class="form-control yathra-form-input"/>
                        <label for="district">
                            No. of Seats
                        </label>
                        <input type="number" name="seats" placeholder="No. of seats" value={pkg_data.seats} onChange={handleChange} class="form-control yathra-form-input"/>

                        <button onClick={data ? updatePackage : addPackage}> {data ? 'Update Package' :'Add Package'}</button>
                        {/* <label for="state" class="form-label">State</label>
                        <select id="state" class="form-select yathra-form-input">
                            <option selected>Choose...</option>
                            <option>...</option>
                        </select>
                        <label for="country">
                            Country
                        </label>
                        <select id="country" class="form-select yathra-form-input">
                            <option selected>Choose...</option>
                            <option>...</option>
                        </select>
                        <label for="zipcode">
                            Zipcode
                        </label>
                        <input type="text" name="zipcode" id="zipcode" placeholder="zipcode" class="form-control yathra-form-input"/> */}
                    {/* </div> */}
                    {/* <div class="tab-pane fade" id="pills-plans" role="tabpanel" aria-labelledby="pills-plans-tab" tabindex="0">
                        <label for="dayCount" class="form-label">Choose No. of Days:</label>
                        <input type="number" name="dayCount" id="dayCount" placeholder="dayCount" class="form-control yathra-form-input" />
                        <label for="day1"></label>
                        <input type="text" name="day1" id="day1" value="day1" placeholder="day1" class="form-control yathra-form-input" disabled />
                        <label for="dayPlan1">
                            Plan Description
                        </label>
                        <textarea name="dayPlan1" id="dayPlan1" cols="30" rows="10" class="form-control yathra-form-input"></textarea>
                        <input type="submit" name="addDay" id="addDay" value="ADD" class="btn btn-primary"/>
                    </div>
                    <div class="tab-pane fade" id="pills-hotels" role="tabpanel" aria-labelledby="pills-hotels-tab" tabindex="0">
                        <label for="hotelName">Hotel Name</label>
                        <select name="hotelName" id="hotelName" class="form-select yathra-form-input">
                            <option value="" selected>choose...</option>
                            <option value="">...</option>
                        </select>
                        <label for="roomCategory">Room Category</label>
                        <select name="roomCategory" id="roomCategory" class="form-select yathra-form-input">
                            <option value="" selected>choose...</option>
                            <option value="">...</option>
                        </select>
                    </div>
                    <div class="tab-pane fade" id="pills-transportation" role="tabpanel" aria-labelledby="pills-transportation-tab" tabindex="0">...</div> */}
                  </div>


                
                    
                </form>
            </div>
        //  </div>
    // </main>
  )
}
