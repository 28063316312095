import React from "react";
import {Routes,Route} from "react-router-dom";
import Home from "./Pages/home/home";
import Login from './Components/Login/login';

const Components = ()=>{
    return(

        <Routes>

        <Route exact path='/' element={<Home/>}/>
        <Route exact path='/yathra' element={<Home/>}/>
        <Route exact path='/login' element={<Login/>}/>
        {/* <Route exact path="/dashboard/*" element={<Dashboard/>}>

        <Route exact path='/users' element={<Userlist/>}/>

        </Route> */}

        {/* <Route exact path='/dashboard/users' element={<Userlist/>}/>
        <Route exact path='/dashboard/editusers' element={<User/>}/> */}
        </Routes>

    );
}




export default Components;