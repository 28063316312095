import React from 'react'
import Homepageform from '../../Components/form/homepage-form';
import './home.css'
import  Image1 from '../../Components/assets/images/image1.png';
import  Image2 from '../../Components/assets/images/image2.png';
import  Image3 from '../../Components/assets/images/image3.png';
import logo from '../../Components/assets/logo/logo.png';
import ContactForm from '../../Components/form/contact-form';
import { BsInstagram,BsWhatsapp,BsTablet,BsEnvelope } from "react-icons/bs";
import 'bootstrap/dist/css/bootstrap.css';
import {Row,Col} from 'react-bootstrap';
import Homeslider from './home-slider';

function Home() {
    return (
      <div>
        <Row>
            <Homeslider/>
            {/* <Col>
                <h1>Rediscover your inner calling.</h1>
                <span className='description'>"In today's fast-paced world, we are constantly searching for happiness. Pause. No. Stop. Close your eyes. Take a deep breath. Let go of the desires of ego. Embark on a voyage. Of discovery,
                of introspection, of happiness or revelation. Discover the happiness, the perfection, the treasure you seek within you. Come, and join us on this journey of a lifetime! "
                </span>
            </Col>
            <Col  className='box'>
                    <Homeslider/>
            </Col> */}
        </Row>
        <Row className='section'>
            <Col>
                <img src={Image1} alt=''/>
            </Col>
            <Col>
                <h1>What You Seek</h1>
                <span className='description'>
                Inner peace? A spiritual getaway with your loved ones? A voyage of discovery? Or is it one of those sojourns to say thanks to the gods for making your wishes come true? You name it, and we will help you find what you are seeking.
                </span>
            </Col>
        </Row>
        <Row className='section'>
            <Col>
                <h1>What we give you</h1>
                <span className='description'>
                We live in a land where myths abound. Where gods walk among man. Where life is a celebration. Where festivals are a way of life. We hope to inspire, reveal, regale and give you an experience that will pique your curiosity about the beautiful and inspiring world we live in.
                </span>
            </Col>
            <Col>
                <img src={Image2} alt=''/>
            </Col>
        </Row>
        <Row className='section'>
            <Col>
                <img src={Image3} alt=''/>
            </Col>
            <Col>
                <div className='logo'><img src={logo} alt=''/></div>
                <span className='description'>
                At Teerthayatra, we bring you multi-media content, special packages and introspective journeys that will leave you spellbound and asking for more. Remarkable places, unusual offerings and rarest of rare experiences from around you 
                </span>
            </Col>
        </Row>
        <Row className='section'>
            <Col className='contact-info'>
                <h2>Contact <span>Us</span></h2>
                <span><i><BsEnvelope/></i> teerthayatra@gmail.com</span>
                <span><i><BsTablet/></i> +91 91017 77771</span>
                <span><i><BsWhatsapp/></i> +91 91017 77771</span>
                <span><i><BsInstagram/></i> teerthayatra</span>
            </Col>
            <Col>
            <ContactForm/>
            </Col>
        </Row>
      </div>
    )
  }
  
  export default Home;