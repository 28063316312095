import React from 'react'
import '../sidebar/sidebar.css'
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import Shop from '@mui/icons-material/ShoppingBasket';
import HomeIcon from '@mui/icons-material/Home';
import Inventory from '@mui/icons-material/Inventory';
import Orders from '@mui/icons-material/Note';
import TravelExplore from '@mui/icons-material/TravelExplore';
import Payment from '@mui/icons-material/Payment';
import Category from '@mui/icons-material/Category';
import Offers from '@mui/icons-material/LocalOfferSharp';
import Booking from '@mui/icons-material/CalendarMonth';
import History from '@mui/icons-material/History';
import Hotel from '@mui/icons-material/Hotel';
import Blog from '@mui/icons-material/PostAdd';
import List from '@mui/icons-material/List';
import Comment from '@mui/icons-material/Comment';
import PhotoLibrary from '@mui/icons-material/PhotoLibrary';
import AddAPhoto from '@mui/icons-material/AddAPhoto';
import Admin from '@mui/icons-material/AdminPanelSettingsRounded';
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import User from '@mui/icons-material/SupervisedUserCircle';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { NavLink } from 'react-router-dom';

export default function Sidebar() {
  return (
    <div className='sidebar'>
        {/* <div className="sidebarWrapper"> */}
        <div className="sidebarMenu">

            <ul className="sidebarList">
              <NavLink to='charts' className="sidebarLink">
                <li className="sidebarListItem">
                    <HomeIcon className='sidebarIcon'/>
                         Dashboard
                </li>
                </NavLink>

                <h3 className="sidebarTitle">Home Page</h3>
                <NavLink to='homepage-slider' className="sidebarLink">
                <li className="sidebarListItem">
                    <Booking className='sidebarIcon'/>
                        Home page Slider
                </li>
                </NavLink>
                <NavLink to='add_gallery' className="sidebarLink">
                <li className="sidebarListItem">
                    <Booking className='sidebarIcon'/>
                        Gallery
                </li>
                </NavLink>

                <h3 className="sidebarTitle">Yathras</h3>
                <NavLink to='live-bookings' className="sidebarLink">
                <li className="sidebarListItem">
                    <Booking className='sidebarIcon'/>
                        Live Bookings
                </li>
                </NavLink>

                <NavLink to='bookings-history' className="sidebarLink">
                <li className="sidebarListItem">
                    <History className='sidebarIcon'/>
                        Booking History
                </li>
                </NavLink>

                <NavLink to='usersList' className="sidebarLink">
                <li className="sidebarListItem">
                    <Category className='sidebarIcon'/>
                        Categories
                </li>
                </NavLink>

                <NavLink to='yathra-payments' className="sidebarLink">
                <li className="sidebarListItem">
                    <Payment className='sidebarIcon'/>
                       Payments
                </li>
                </NavLink>

                <NavLink to='usersList' className="sidebarLink">
                <li className="sidebarListItem">
                    <Offers className='sidebarIcon'/>
                        Offers
                </li>
                </NavLink>

                <h3 className="sidebarTitle">User Management</h3>
                <NavLink to='users' className="sidebarLink">
                <li className="sidebarListItem">
                    <User className='sidebarIcon'/>
                        Users
                </li>
                </NavLink>
                
                <h3 className="sidebarTitle">Yathra Packages</h3>
                <NavLink to='yathra_packages' className="sidebarLink">
                <li className="sidebarListItem">
                    <TravelExplore className='sidebarIcon'/>
                       Add Yathra Packages
                </li>
                </NavLink>

                <NavLink to='manage-yathras' className="sidebarLink">
                <li className="sidebarListItem">
                    <TravelExplore className='sidebarIcon'/>
                        Manage Packages
                </li>
                </NavLink>

                <h3 className="sidebarTitle">Shop</h3>
                <NavLink to='orders' className="sidebarLink">
                <li className="sidebarListItem">
                    <Orders className='sidebarIcon'/>
                       Orders
                </li>
                </NavLink>

                <NavLink to='inventory' className="sidebarLink">
                <li className="sidebarListItem">
                    <Inventory className='sidebarIcon'/>
                       Inventory
                </li>
                </NavLink>

                <NavLink to='shop-payments' className="sidebarLink">
                <li className="sidebarListItem">
                    <Payment className='sidebarIcon'/>
                       Payments
                </li>
                </NavLink>

                <NavLink to='AddOffers' className="sidebarLink">
                <li className="sidebarListItem">
                    <Category className='sidebarIcon'/>
                       Category
                </li>
                </NavLink>

                <NavLink to='AddOffers' className="sidebarLink">
                <li className="sidebarListItem">
                    <Offers className='sidebarIcon'/>
                       Offers
                </li>
                </NavLink>

                <h3 className="sidebarTitle">Shop</h3>
                <NavLink to='AddShopItem' className="sidebarLink">
                <li className="sidebarListItem">
                    <Shop className='sidebarIcon'/>
                       Add Shop Item
                </li>
                </NavLink>

                <NavLink to='ManageShopItem' className="sidebarLink">
                <li className="sidebarListItem">
                    <Shop className='sidebarIcon'/>
                        Manage Shop Item
                </li>
                </NavLink>
                    
                <h3 className="sidebarTitle">Hotels</h3>
                <NavLink to='AddHotels' className="sidebarLink">
                <li className="sidebarListItem">
                    <Hotel className='sidebarIcon'/>
                       Add Hotels
                </li>
                </NavLink>

                <NavLink to='ManageHotels' className="sidebarLink">
                <li className="sidebarListItem">
                    <Hotel className='sidebarIcon'/>
                        Manage Hotels
                </li>
                </NavLink>
                
                <h3 className="sidebarTitle">Blog</h3>
                <NavLink to='AddPosts' className="sidebarLink">
                <li className="sidebarListItem">
                    <Blog className='sidebarIcon'/>
                       Add Posts
                </li>
                </NavLink>

                <NavLink to='ManagePosts' className="sidebarLink">
                <li className="sidebarListItem">
                    <List className='sidebarIcon'/>
                        Manage Posts
                </li>
                </NavLink>

                <NavLink to='ManagePosts' className="sidebarLink">
                <li className="sidebarListItem">
                    <Comment className='sidebarIcon'/>
                        Comments
                </li>
                </NavLink>

                <h3 className="sidebarTitle">Gallery</h3>
                <NavLink to='ManageUsers' className="sidebarLink">
                <li className="sidebarListItem">
                    <PhotoLibrary className='sidebarIcon'/>
                       Library
                </li>
                </NavLink>

                <NavLink to='add_gallery' className="sidebarLink">
                <li className="sidebarListItem">
                    <AddAPhoto className='sidebarIcon'/>
                       Add Media
                </li>
                </NavLink>

                <NavLink to='ManageUsers' className="sidebarLink">
                <li className="sidebarListItem">
                    <Category className='sidebarIcon'/>
                       Category
                </li>
                </NavLink>

                <h3 className="sidebarTitle">Users</h3>
                <NavLink to='ManageUsers' className="sidebarLink">
                <li className="sidebarListItem">
                    <Admin className='sidebarIcon'/>
                       Admin
                </li>
                </NavLink>

                <NavLink to='ManageUsers' className="sidebarLink">
                <li className="sidebarListItem">
                    <VerifiedUser className='sidebarIcon'/>
                       Yathra Manager
                </li>
                </NavLink>

                <NavLink to='ManageUsers' className="sidebarLink">
                <li className="sidebarListItem">
                    <VerifiedUser className='sidebarIcon'/>
                       Shop Keeper
                </li>
                </NavLink>

                <NavLink to='ManageUsers' className="sidebarLink">
                <li className="sidebarListItem">
                    <User className='sidebarIcon'/>
                       Clients
                </li>
                </NavLink>

                <li className="sidebarListItem">
                    <LiveHelpIcon className='sidebarIcon'/>FAQ
                </li>
            </ul>
        {/* </div> */}
        </div>
    </div>
  )
}
