import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

//import images
import packageimg1 from '../../Components/assets/images/home3.jpg'
import packageimg2 from '../../Components/assets/images/home4.jpg'
import packageimg3 from '../../Components/assets/images/home5.jpg'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./home-slider.css";

import { Autoplay} from "swiper";
import http from "../../axios";

export default function Homeslider() {

  const [sliderImages, setSlider] = useState([]);

  useEffect( () =>{

    loadSlider();

    },[0]);

    const loadSlider = async() =>{
      console.log('asdasda');

      let response = await http.get('user/slider/all')
        
        .then( response => {

          setSlider(response.data.data);
          console.log(response.data.data);
        });

      
      // console.log('asdasda',response);
    }
    
  return (
    <>
      <Swiper
        slidesPerView={1}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        spaceBetween={30}
        loop={true}
        modules={[Autoplay]}
        className="HomeSwiper"
      >
        {sliderImages[0] ?
          sliderImages[0].slider_images.map((item,index)=>{
            return <SwiperSlide key={index} className="HomeSlider-container">
                      <img src={process.env.REACT_APP_baseURL+item} alt=""  className="HomeSingleSLiderImg"/>
                      <div className="HomesliderContent">
                          <h1>Rediscover your inner calling.</h1>
                          <span className='description'>"In today's fast-paced world, we are constantly searching for happiness. Pause. No. Stop. Close your eyes. Take a deep breath. Let go of the desires of ego. Embark on a voyage. Of discovery,
                of introspection, of happiness or revelation. Discover the happiness, the perfection, the treasure you seek within you. Come, and join us on this journey of a lifetime! "
                          </span>
                      </div>
                  </SwiperSlide>
          }) :
          <SwiperSlide className="HomeSlider-container">
                      <img src={packageimg1} alt=""  className="HomeSingleSLiderImg"/>
                      <div className="HomesliderContent">
                          <h1>Rediscover your inner calling.</h1>
                          <span className='description'>"In today's fast-paced world, we are constantly searching for happiness. Pause. No. Stop. Close your eyes. Take a deep breath. Let go of the desires of ego. Embark on a voyage. Of discovery,
                of introspection, of happiness or revelation. Discover the happiness, the perfection, the treasure you seek within you. Come, and join us on this journey of a lifetime! "
                          </span>
                      </div>
                  </SwiperSlide>

        }        
        
      </Swiper>
    </>
  );
}
