import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import http from "../../../axios";
import { FaTrashAlt } from "react-icons/fa";

export default function AddGallery() {

    const [selectedImages, setSelectedImages] = useState([]);
    const [images, setImages] = useState([]);
    const [current_images, setImg] = useState([]);
    const navigate = useNavigate();


    useEffect(()=>{

      getData();

    },[0])

    const getData = () =>{
      let response = http.get('user/gallery/all')
      .then( response => {

        var data = response.data.data[0];
        if(data){
          setImg(data.gallery_images);
        }
        // console.log(response.data.data[0]);

      });
    }

    const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    setImages(selectedFilesArray);
    const imagesArray = selectedFilesArray.map((file) => {
    return URL.createObjectURL(file);
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
    };

    const notify = () => toast.success("Added to gallery");


    const addGallery = async() =>{

        console.log(images);

        let formData = new FormData();
        
        images.forEach(item => {
            formData.append('gallery_images',item);
        });

        let response = await http.post('admin/gallery/add',formData,{
            headers:{
              'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
              'Content-Type': 'multipart/form-data'
            }
          })
          .then( response => {
    
            console.log(response);
            notify();
            getData();
            setSelectedImages('');
            // navigate('/dashboard/add_gallery');
      
          });

    }


    function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
    }

  return (
    <div>
        <ToastContainer theme="colored" pauseOnHover={false}/>
      <h1>Add Images to Gallery</h1>
      <section className="homePageSliderEdit_Section">

    <label className="homePageSliderEdit_Label">
    + Add Images
    <br />
    <span>up to 10 images</span>
    <input
        type="file"
        name="images"
        onChange={onSelectFile}
        multiple
        accept="image/png , image/jpeg, image/webp"
        className="homePageSliderEdit_Input"
    />
    </label>
    <br />

    <input type="file" multiple className="homePageSliderEdit_Input"/>

        <button
        className="upload-btn" onClick={addGallery}
        >
        UPLOAD IMAGES
        </button>

    <div className="homePageSliderEdit_Images">
    {selectedImages &&
        selectedImages.map((image, index) => {
        return (
            <div key={image} className="homePageSliderEdit_SingleImage">
            <img src={image} height="200" alt="upload" className="homePageSliderEdit_SingleImage_img"/>
            <button onClick={() => deleteHandler(image)}>
                Remove Image
            </button>
            <p>{index + 1}</p>
            </div>
        );
        })}
    </div>    
</section>
<div className="homePageSliderEdit_uploadedImages mb-5">
        <h3>Current Images</h3>
        <div className="d-flex">
          {
            // current_images ? 
            current_images.map((item,index)=>{

              return <div key={index} className="homePageSliderEdit_SingleImage">
                    <img src={process.env.REACT_APP_baseURL+item} alt="" height="200" className="homePageSliderEdit_SingleImage_img"/>
                    <button><FaTrashAlt/></button>
                    </div>

            }) 

          }


          {/* <div className="homePageSliderEdit_SingleImage">
            <img src={packageimg3} alt="" height="200" className="homePageSliderEdit_SingleImage_img"/>
          </div>
          <div className="homePageSliderEdit_SingleImage">
            <img src={packageimg3} alt="" height="200" className="homePageSliderEdit_SingleImage_img"/>
          </div> */}
        </div>
      </div>
    </div>
  )
}
