import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate} from "react-router-dom";
import Dashboard from './AdminPages/Dashboard/Dashboard';
import { useEffect, useState } from 'react';
import Layout from './Components/layout/layout';
import Register from './Components/register';
import Home from './Pages/home/home';
import Header from './Components/Header/header';
import Login from './Pages/Login/login';
import Packagelist from './Pages/yathrapackages/packagelist/packagelist';
import YathapackagesTimeline from './Pages/yathrapackages/yathapackagesTimeline';
import ViewPackage from './Pages/yathrapackages/view-package/ViewPackage';
import DemoPackagelist from './Pages/demo packages/packagelist/demo-packagelist';
import PackageSlider from './Pages/demo packages/packagelist/slider/slider';
import DemoViewpackage from './Pages/demo packages/view-package/demo-viewpackage';
import SingleImageSlider from './Pages/demo packages/view-package/slider/viewpackage-single-image-slider';
import MultipleSlider from './Pages/demo packages/view-package/slider/ViewPackage-multipleSlider';
import ViewpackageTabbedDetails from './Pages/demo packages/view-package/tabs/viewpackage-tabbed-details';
import AdminLogin from './Pages/Login/admin_login';
import BookPackages from './Pages/demo packages/booking/bookPackages';
import Homeslider from './Pages/home/home-slider';
import Gallery from './Pages/gallery/gallery';

function App() {

  const navigate = useNavigate();

  const [isLoggedin,setIsLoggedin] = useState();

  useEffect(() => {

    
    // navigate("/", {replace : true} )
    
  },[]);

  const onClickHandler = () => {
    console.log('button was clicked');
    setIsLoggedin(true);
  }



  return (
    <div className="App">

      <Routes>
        <Route exact path='dashboard/*' element={<Dashboard/>}/>
        <Route exact path='/*' element={<Layout/>}/>
        <Route exact path='login/*' element={<Login/>}/>
        <Route exact path='Package-timeline/*' element={<YathapackagesTimeline/>}/>
        <Route exact path='Packagelist/*' element={<DemoPackagelist/>}/>
        <Route exact path='DemoPackagelist/*' element={<DemoPackagelist/>}/>
        <Route exact path='Slider/*' element={<PackageSlider/>}/>
        
        <Route exact path='View-package-details/*' element={<DemoViewpackage/>}/>
        <Route exact path='DemoViewPackage/*' element={<DemoViewpackage/>}/>
        <Route exact path='SingleSlider/*' element={<SingleImageSlider/>}/>
        <Route exact path='MultipleSlider/*' element={<MultipleSlider/>}/>
        <Route exact path='ViewpackageTabbedDetails/*' element={<ViewpackageTabbedDetails/>}/>
        <Route exact path='admin_login' element={<AdminLogin/>}/>
        <Route exact path='booking' element={<BookPackages/>}/>
        <Route exact path='HomeSlider' element={<Homeslider/>}/>
        <Route exact path='gallery' element={<Gallery/>}/>
      </Routes>

      {/* <Register/> */}

      {/* <Dashboard/> */}
      {/* <Header/>
      <Home/> */}

    </div>
  );
}

export default App;
