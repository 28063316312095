import React, { useRef, useState } from "react";
import { Rating } from 'react-simple-star-rating'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import packageimg1 from '../img/service-3 1.png'
import packageimg2 from '../img/service-1 1.png'
import packageimg3 from '../img/service-2 1.png'

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import '../slider/slider.css'

// import required modules
import { Navigation } from "swiper";

export default function PackageSlider() {

    //Star Rating
    const [rating, setRating] = useState(0)

    // Catch Rating value
    const handleRating = (rate: number) => {
      setRating(rate)
    }
    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = (value: number, index: number) => console.log(value, index)

  return (
      <div className="slider-main-container">
          <Swiper loop={true} navigation={true} modules={[Navigation]} className="mySwiper">
            <div className="slider-content m-2">
                <SwiperSlide>
                <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                        <div className="SliderPackageImage">
                          <img src ={packageimg1} alt=''/>
                        </div>
                        <div className="SliderPackageDetails">
                          <div className="container">
                            <h2 className="packagename">
                                The wind of kaashi
                            </h2>
                            <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                              incididunt ut laborea. 
                            </p>
                            <Rating
                              onClick={handleRating}
                              onPointerEnter={onPointerEnter}
                              onPointerLeave={onPointerLeave}
                              onPointerMove={onPointerMove}
                              size={27}
                              /* Available Props */
                            />
                            <br />
                            <button className='btn explorebtn px-4'>Explore</button>
                          </div>
                        </div>
                      </div>
                </SwiperSlide>
            </div>

            <div className="slider-content">
                <SwiperSlide>
                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
                        <div className="SliderPackageImage">
                          <img src ={packageimg2} alt=''/>
                        </div>
                        <div className="SliderPackageDetails">
                          <div className="container">
                            <h2 className="packagename">
                                The wind of kaashi
                            </h2>
                            <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                              incididunt ut laborea. 
                            </p>
                            <Rating
                              onClick={handleRating}
                              onPointerEnter={onPointerEnter}
                              onPointerLeave={onPointerLeave}
                              onPointerMove={onPointerMove}
                              size={27}
                              /* Available Props */
                            />
                            <br />
                            <button className='btn explorebtn px-4'>Explore</button>
                          </div>
                        </div>
                      </div>
                </SwiperSlide>
            </div>

            <div className="slider-content">
                <SwiperSlide>
                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
                        <div className="SliderPackageImage">
                          <img src ={packageimg3} alt=''/>
                        </div>
                        <div className="SliderPackageDetails">
                          <div className="container">
                            <h2 className="packagename">
                                The wind of kaashi
                            </h2>
                            <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                              incididunt ut laborea. 
                            </p>
                            <Rating
                              onClick={handleRating}
                              onPointerEnter={onPointerEnter}
                              onPointerLeave={onPointerLeave}
                              onPointerMove={onPointerMove}
                              size={27}
                              /* Available Props */
                            />
                            <br />
                            <button className='btn explorebtn px-4'>Explore</button>
                          </div>
                        </div>
                      </div>
                </SwiperSlide>
            </div>
          </Swiper>
    </div>
  );
}
