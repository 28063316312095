import React, { useState } from 'react'
import LoginImg from './components/Artboard 1.png'
import Header from '../../Components/Header/header'
import './login.css'
import http from '../../axios'
import { useNavigate } from 'react-router-dom'

export default function AdminLogin() {

  const [formdata , setState] = useState({})

  const navigate = useNavigate();

  const handleChange  = (e) =>{


    console.log(e.target.name);

    setState(prevState => ({
      ...prevState,
      [e.target.name] : e.target.value
    }))

  }

  const adminLogin = async() =>{

    let response = http.post('admin/login',{
      email : formdata.email,
      password: formdata.password
    })
      
      .then((res)=>{
        console.log(res.data.data.token);
        localStorage.setItem('admin_token',res.data.data.token);
        navigate('/dashboard');
      });

  }

  return (
    <div>
      <Header/>
    <div className='MainContainer'>
      <div className="LoginContainer">
    <input type="checkbox" id="flip"/>
    <div className="cover">
      <div className="front">
        <img src={LoginImg} alt=""/>
        <div className="text">
          <img src={LoginImg} alt=""/>
        </div>
      </div>
      <div className="back">
        <img className="backImg" src={LoginImg} alt=""/>
        <div className="text">
          <span className="text-1">Complete miles of journey <br/> with one step</span>
          <span className="text-2">Let's get started</span>
        </div>
      </div>
    </div>
    <div className="forms">
        <div className="form-content">
          <div className="login-form">
            <div className="title">Admin Login</div>
          <form action="#">
            <div className="input-boxes">
              <div className="input-box">
                <i className="fas fa-envelope"></i>
                <input name='email' type="text" placeholder="Enter your email" onChange={handleChange} required/>
              </div>
              <div className="input-box">
                <i className="fas fa-lock"></i>
                <input name='password' type="password" placeholder="Enter your password" onChange={handleChange} required/>
              </div>
              <div className="text"><a>Forgot password?</a></div>
              <div className="button input-box">
                <input type="button" onClick={adminLogin} value="Submit"/>
              </div>
            </div>
        </form>
      </div>
        <div className="signup-form">
          <div className="title">Signup</div>
          <form action="#">
            <div className="input-boxes">
              <div className="input-box">
                <i className="fas fa-user"></i>
                <input name='username' type="text" placeholder="Enter your name" required/>
              </div>
              <div className="input-box">
                <i className="fas fa-envelope"></i>
                <input name='email' type="text" placeholder="Enter your email" required/>
              </div>
              <div className="input-box">
                <i className="fas fa-envelope"></i>
                <input name='phone' type="tel" placeholder="Enter your phone number"  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required/>
              </div>
              <div className="input-box">
                <i className="fas fa-lock"></i>
                <input name='password' type="password" placeholder="Enter your password" required/>
              </div>
              <div className="text sign-up-text">Already have an account? <label for="flip">Login now</label></div>
            </div>
       </form>
    </div>
    </div>
    </div>
  </div>
    </div>
    </div>
  )
}
