import axios from "axios";

const http = axios.create({
    baseURL: 'http://localhost:4000/',
    headers: {
              // 'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
              'Content-Type': "application/json",
              'timeout' : 1000
            }
  });

export default http;  