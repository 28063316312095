import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Charts from "./AdminPages/pages/home/charts/charts";
import Userlist from "./AdminPages/pages/userlist/userlist";
import ManagePackages from "./AdminPages/pages/yathras/yathra packages/managePackages";
import Yathrapackagesnew from "./AdminPages/pages/yathras/yathra packages/yathrapackagesnew";

import jwt_decode from "jwt-decode";
import HomePageSliderEdit from "./AdminPages/pages/homepage/homePageSliderEdit";
import AddGallery from "./AdminPages/pages/add_gallery/add_gallery";


const AdminProtected = () =>{

    const isLoggedIn = localStorage.getItem('admin_token');

    if(isLoggedIn){

        const {exp} = jwt_decode(isLoggedIn);

        const expirationTime = (exp * 1000) - 60000

        if (Date.now() >= expirationTime) {
            console.log('expired');
            // set LocalStorage here based on response;
            localStorage.removeItem('admin_token');
          }

        // console.log('exp',exp);
        // console.log(expirationTime);

    }

    if(!isLoggedIn){
        return <Navigate to='/admin_login' />
    }

    return (
        <Routes>

          <Route path="/charts" element={<Charts/>}/>
          <Route path="/homepage-slider" element={<HomePageSliderEdit/>}/>
          <Route path="/yathra_packages" element={<Yathrapackagesnew/>}/>
          <Route path="/manage-yathras" element={<ManagePackages/>}/>
          <Route path="/users" element={<Userlist/>}/>
          <Route path="/add_gallery" element={<AddGallery/>}/>

          {/* <Route path="/hotels" element={<Hotels/>}/>
          <Route path="/live-bookings" element={<LiveBooking/>}/>
          <Route path="/bookings-history" element={<BookingHistory/>}/>
          <Route path="/yathra-payments" element={<YathraPayments/>}/>
          <Route path="/orders" element={<Orders/>}/>
          <Route path="/shop-payments" element={<ShopPayments/>}/>
          <Route path="/inventory" element={<Inventory/>}/> */}
          

        </Routes>
    );

}

export default AdminProtected;