import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import Header from '../../../Components/Header/header';

import '../packagelist/packagelist.css'
import img13 from '../packagelist/img/13.jpg'
import img14 from '../packagelist/img/14.jpg'
import img15 from '../packagelist/img/15.jpg'
import img16 from '../packagelist/img/16.jpg'
import img17 from '../packagelist/img/17.jpg'
import img3 from '../packagelist/img/3.jpg'
import img4 from '../packagelist/img/4.jpg'
import img11 from '../packagelist/img/11.jpg'
import http from '../../../axios';

const packages = '';

export default class Packagelist extends Component {

  constructor(props) {
    super(props);

    this.state = {packages: []};
  }

  componentDidMount(){

      let response = http.get('admin/package/all')
      
      .then( response => {

        console.log('data',response.data.data);
        this.setState({packages :response.data.data});
      });

      

  }

  render() {
    return (
      <div>
        <Header/>
        <section className='main-section'>

          {/* <div className="packagelist-container">

            <div className="packagelist-left">
              <div className= "img-display">
                <div className= "img-showcase">
                  <img src ={img11} alt=''/>
                </div>
              </div>
            </div>

            <div className="packagelist-right">
              <h3>Teerthayatra Package 1</h3>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                Iste distinctio, amet repudiandae facere praesentium et pariatur 
                placeat commodi fuga voluptatum cumque! Ducimus maiores harum repudiandae 
                atque ipsum eaque iste dolor?</p>
                <Link to="/view-package-details" className='details-btn'> View more details </Link> 

                <div class = "product-price">
                  <p class = "last-price">Old Price: <span>$257.00</span></p>
                  <p class = "new-price">New Price: <span>$249.00</span></p>
                </div>
            </div>

          </div> */}

          {
            this.state.packages.map((item,index)=>{

              return(
                <div key={index} className="packagelist-container">

                  <div className="packagelist-left">
                    <div className= "img-display">
                      <div className= "img-showcase">
                        <img src ={item.pkg_image} alt=''/>
                      </div>
                    </div>
                  </div>

                  <div className="packagelist-right">
                    <h3>{item.pkg_name}</h3>
                    <p>{item.description}</p>
                      <Link to="/view-package-details" className='details-btn'> View more details </Link> 

                      <div class = "product-price">
                        <p>Booking Starts:<span>{item.start_date}</span></p><p>Booking closes:<span>{item.close_date}</span></p>
                        <p class = "new-price">New Price: <span> ₹{item.price}</span></p>
                      </div>
                  </div>

                </div>
              )

            })
          }

          {/* <div className="packagelist-container">

            <div className="packagelist-left">
              <div className= "img-display">
                <div className= "img-showcase">
                  <img src ={img17} alt=''/>
                </div>
              </div>
            </div>

            <div className="packagelist-right">
              <h3>Teerthayatra Package 3</h3>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                Iste distinctio, amet repudiandae facere praesentium et pariatur 
                placeat commodi fuga voluptatum cumque! Ducimus maiores harum repudiandae 
                atque ipsum eaque iste dolor?</p>
                <Link to="/view-package-details" className='details-btn'> View more details </Link> 

                <div class = "product-price">
                  <p class = "last-price">Old Price: <span>$257.00</span></p>
                  <p class = "new-price">New Price: <span>$249.00</span></p>
                </div>
            </div>

          </div>

          <div className="packagelist-container">

            <div className="packagelist-left">
              <div className= "img-display">
                <div className= "img-showcase">
                  <img src ={img14} alt=''/>
                </div>
              </div>
            </div>

            <div className="packagelist-right">
              <h3>Teerthayatra Package 4</h3>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                Iste distinctio, amet repudiandae facere praesentium et pariatur 
                placeat commodi fuga voluptatum cumque! Ducimus maiores harum repudiandae 
                atque ipsum eaque iste dolor?</p>
                <Link to="/view-package-details" className='details-btn'> View more details </Link> 

                <div class = "product-price">
                  <p class = "last-price">Old Price: <span>$257.00</span></p>
                  <p class = "new-price">New Price: <span>$249.00</span></p>
                </div>
            </div>

          </div>

          <div className="packagelist-container">

            <div className="packagelist-left">
              <div className= "img-display">
                <div className= "img-showcase">
                  <img src ={img16} alt=''/>
                </div>
              </div>
            </div>

            <div className="packagelist-right">
              <h3>Teerthayatra Package 5</h3>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                Iste distinctio, amet repudiandae facere praesentium et pariatur 
                placeat commodi fuga voluptatum cumque! Ducimus maiores harum repudiandae 
                atque ipsum eaque iste dolor?</p>
                <Link to="/view-package-details" className='details-btn'> View more details </Link> 

                <div class = "product-price">
                  <p class = "last-price">Old Price: <span>$257.00</span></p>
                  <p class = "new-price">New Price: <span>$249.00</span></p>
                </div>
            </div>

          </div> */}
        </section>
      </div>
    )
  }
}
