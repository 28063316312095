import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import '../tabs/viewpackage-tabs.css'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ViewpackageTabbedDetails() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%'}}>
      <Box sx={{ borderBottom: 0, background:'#E1E1E1', mb:'20px',borderRadius: '10px'}}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
         TabIndicatorProps={{ color:'#000'}}>
          <Tab sx={{ fontWeight: '600',fontFamily: 'poppins', textTransform: 'capitalize'}} label="Plans" {...a11yProps(0)} />
          {/* <Tab sx={{ fontWeight: '600',fontFamily: 'poppins', textTransform: 'capitalize'}} label="Transportation" {...a11yProps(1)} />
          <Tab sx={{ fontWeight: '600',fontFamily: 'poppins', textTransform: 'capitalize'}} label="Hotels" {...a11yProps(2)} />
          <Tab sx={{ fontWeight: '600',fontFamily: 'poppins', textTransform: 'capitalize'}} label="Activities" {...a11yProps(3)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} className='tabPanel'>
        <div className="viewpackage-tabbed-maincontainer d-flex">
            <div className="col-6 viewpackage-tabs-left-container">
                <h4>The wind of kaashi</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate dignissim suspendisse in est ante in. Pulvinar sapien et ligula ullamcorper malesuada. Vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Gravida cum sociis natoque penatibus et magnis. Ullamcorper velit sed ullamcorper morbi tincidunt. Accumsan lacus vel facilisis volutpat est velit egestas dui id. Dolor magna eget est lorem ipsum dolor sit. Tempus egestas sed sed risus pretium. Vel pretium lectus quam id. Egestas tellus rutrum tellus pellentesque eu tincidunt tortor. Pellentesque eu tincidunt tortor aliquam nulla facilisi crast.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate dignissim suspendisse in est ante in. Pulvinar sapien et ligula ullamcorper malesuada. Vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Gravida cum sociis natoque penatibus et magnis. Ullamcorper velit sed ullamcorper morbi tincidunt. Accumsan lacus vel facilisis volutpat est velit egestas dui id. Dolor magna eget est lorem ipsum dolor sit. Tempus egestas sed sed risus pretium. Vel pretium lectus quam id. Egestas tellus rutrum tellus pellentesque eu tincidunt tortor. Pellentesque eu tincidunt tortor aliquam nulla facilisi crast.</p>
            </div>
            <div className="col-6 viewpackage-tabs-right-container">
                <h4>Day 1</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate dignissim suspendisse in est ante in. </p>
            
                <h4>Day 2</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate dignissim suspendisse in est ante in. </p>
            
                <h4>Day 3</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate dignissim suspendisse in est ante in. </p>
            
                <h4>Day 4</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate dignissim suspendisse in est ante in. </p>
            
                <h4>Day 5</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate dignissim suspendisse in est ante in. </p>
            </div>
        </div>
      </TabPanel>

      {/* <TabPanel value={value} index={1} className='tabPanel'>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2} className='tabPanel'>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3} className='tabPanel'>
        Item Four
      </TabPanel> */}
    </Box>
  );
}