import React, {useState } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle';

import './booking.css'
import http from '../../../axios';
import { useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

export default function BookPackages() {

  const [inputList, setInputList] = useState([]);
  const [data, setData] = useState({pkg_id:'',user_id:'',person:{},amount:''});
  const location = useLocation();
  // console.log('loc',location.state.pkg_id);
  const pkg_id = location.state.pkg_id;


        const Input = () => {
            return (
              <>
                <h4 className='p-3'>Voyager</h4>
                <div className="d-flex">
                  <input id={inputList.length} type="text" name="name" onChange={(e)=>onHandleChange(e)} className="form-control width80 m-2" placeholder='Enter Your Name'/>
                  <input id={inputList.length} type="number" onChange={(e)=>onHandleChange(e)} name="age" className="form-control width20 m-2" placeholder='Age'/>
                </div>
                <div className="d-flex">
                  <select id={inputList.length} name='gender' onChange={(e)=>onHandleChange(e)} className="form-control width20 m-2" placeholder='Enter Your Name'>
                  <option value="">Select Gender</option>
                  <option value="m">Male</option>
                  <option value="f">Female</option>
                  <option value="o">Other</option>
                  </select>
                  <input id={inputList.length} type="tel" onChange={(e)=>onHandleChange(e)} name="phone" className="form-control width80 m-2" placeholder='Enter Mobile Number'/>
                </div>
                <div className="d-flex">
                  <input id={inputList.length} type="Number" onChange={(e)=>onHandleChange(e)} name="aadhaar" className="form-control m-2" placeholder='Enter Aadhar Number'/>
                </div>
              </>
            );
        };
        
        const [person, setPerson] = useState([])

          const onAddBtnClick = event => {
            setInputList(inputList.concat(<Input key={inputList.length} />));
            person.push({name:'',phone:'',age:'',gender:'',aadhaar:''});
            console.log(person);
            // onHandleChange();
          };


          const onHandleChange = (e) =>{
            var val = e.target;
            var names = e.target.name;

            // setPerson({val.name: val.value});
            person[val.id][names] = val.value;
            console.log(person);
          }

          const submit = async() => {

            let data = {
              user_id: localStorage.getItem('user_id'),
              pkg_id: pkg_id,
              person:person
            }
            
            let response = await http.post('user/booking/save',data,{
              headers:{
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            })
            .then( response => {
        
              // navigate('/dashboard/manage-yathras');
              console.log(response);
        
            });

          }

  return (
    <div className='body-container d-flex align-items-center justify-content-center'>
     <div className="container-fluid">
        <div className="container booking-form-wrapper">
          <form action="">
            {/* <h4 className='p-3'>Voyager</h4>
            <div className="d-flex">
              <input type="text" name="name" onChange={(e)=>onHandleChange(e)} className="form-control width80 m-2" placeholder='Enter Your Name'/>
              <input type="number" name="age" onChange={(e)=>onHandleChange(e)} className="form-control width20 m-2" placeholder='Age'/>
            </div>
            <div className="d-flex">
              <select className="form-control width20 m-2" name='gender' onChange={(e)=>onHandleChange(e)} placeholder='Enter Your Name'>
               <option value="">Select Gender</option>
               <option value="">Male</option>
               <option value="">Female</option>
               <option value="">Other</option>
              </select>
              <input type="tel" name="phone" onChange={(e)=>onHandleChange(e)} className="form-control width80 m-2" placeholder='Enter Mobile Number'/>
            </div>
            <div className="d-flex">
              <input type="Number" name="aadhaar" onChange={(e)=>onHandleChange(e)} id="" className="form-control m-2" placeholder='Enter Aadhar Number'/>
            </div> */}
            {inputList}
            <button type='button' className='primary p-2 rounded m-2' onClick={onAddBtnClick}>Add Traveller</button>
            <button type='button' className='primary p-2 rounded m-2' onClick={submit}>Continue to Payment</button>
          </form>
        </div>
     </div>
    </div>
  )
}
