
import React, { useState } from 'react'
// import { useNavigate } from 'react-router';
import http from '../../axios';

function Login() {

  const [email,SetMail] = useState('');
  const [password,SetPassword] = useState('');

 

  const handleLogin = (e) =>{

    e.preventDefault();

    let data = {
      "email":email,
      "password":password
    }
    http.post('api/login',data)
      .then(function (response) {
          // console.log(response.data['token']);
          setToken(response.data['token'])
          // setResponse(response.data.message);

        })
        .catch(function (error) {

          console.log(error.response.data.errors);
          // setErrors(error.response.data.errors);
        });
  }
  const setToken=(token)=>{
    localStorage.setItem('token',token)
    console.log(localStorage.getItem('token'))
    
  }

  return (
    <div>
        <input type='text' placeholder='E-mail' onChange={(e)=>SetMail(e.target.value)}></input>
        <input type='password' placeholder='Password' onChange={(e)=>SetPassword(e.target.value)}></input>
        <button onClick={handleLogin}>Login</button>
    </div>
  )
}

export default Login