import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import http from "../../../axios";
import "./homePageSliderEdit.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrashAlt } from "react-icons/fa";


export default function HomePageSliderEdit() {

    const [selectedImages, setSelectedImages] = useState([]);
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [images, setFiles] = useState('');
    const [current_images, setImg] = useState([]);

    const navigate = useNavigate();


    useEffect(()=>{

      getData();

    },[0])

    const getData = () =>{
      let response = http.get('user/slider/all')
      .then( response => {

        var data = response.data.data[0];
        if(data){
          setTitle(data.tittle);
          setDesc(data.description);
          setImg(data.slider_images);
        }
      });
    }

    const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const imagesArray = selectedFilesArray.map((file) => {
        return URL.createObjectURL(file);
    });
    setFiles(selectedFilesArray);
    setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
    };

    function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
    }

    const notify = () => toast.success("Successfully added!");

    const handleSubmit = async() =>{

      console.log(images);

      let formData = new FormData();

      formData.append('tittle',title);
      formData.append('description',desc);
      
      images.forEach(item => {
        formData.append('slider_images',item);
      });

      let response = await http.post('admin/slider/add',formData,{
        headers:{
          'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then( response => {

        console.log(response);

        notify();
        // navigate('/dashboard/homepage-slider');
        // window.location.reload();
        getData();
        setSelectedImages('');
  
      });


    }
  
  return (
    <div>
      <ToastContainer theme="colored" pauseOnHover={false}/> 
      <section className="homePageSliderEdit_Section">

      <div className="homePageSliderEdit_content">
          <input type="text" name="heading" placeholder="Content Title" value={title ? title : ''} onChange={(e)=>setTitle(e.target.value)} class="form-control yathra-form-input" />
          <textarea type="text" name="content" value={desc ? desc : ''} cols="30" rows="10" onChange={(e)=>setDesc(e.target.value)} class="form-control yathra-form-input"/>
      </div>
      
      <label className="homePageSliderEdit_Label">
        + Add Images
        <br />
        <span>up to 3 images</span>
        <input
          type="file"
          name="images"
          onChange={onSelectFile}
          multiple
          accept="image/png , image/jpeg, image/webp"
          className="homePageSliderEdit_Input"
        />
      </label>
      <br />

      <input type="file" multiple className="homePageSliderEdit_Input"/>

      {selectedImages.length > 0 &&
        (selectedImages.length > 3? (
          <p className="error">
            You can't upload more than 10 images! <br />
            <span>
              please delete <b> {selectedImages.length - 3} </b> of them{" "}
            </span>
          </p>
        ) : (
          <button
            className="upload-btn"
            onClick={handleSubmit}
          >
            UPLOAD IMAGES
          </button>
        ))}

      <div className="homePageSliderEdit_Images">
        {selectedImages &&
          selectedImages.map((image, index) => {
            return (
              <div key={image} className="homePageSliderEdit_SingleImage">
                <img src={image} height="200" alt="upload" className="homePageSliderEdit_SingleImage_img"/>
                <button onClick={() => deleteHandler(image)}>
                  Remove Image
                </button>
                <p>{index + 1}</p>
              </div>
            );
          })}
      </div>
      </section>
      <div className="homePageSliderEdit_uploadedImages mb-5">
        <h3>Current Images</h3>
        <div className="d-flex">
          {
            // current_images ? 
            current_images.map((item,index)=>{

              return <div key={index} className="homePageSliderEdit_SingleImage">
                    <img src={process.env.REACT_APP_baseURL+item} alt="" height="200" className="homePageSliderEdit_SingleImage_img"/>
                    <button><FaTrashAlt/></button>
                    </div>

            }) 

          }


          {/* <div className="homePageSliderEdit_SingleImage">
            <img src={packageimg2} alt="" height="200" className="homePageSliderEdit_SingleImage_img"/>
          </div>
          <div className="homePageSliderEdit_SingleImage">
            <img src={packageimg3} alt="" height="200" className="homePageSliderEdit_SingleImage_img"/>
          </div> */}
        </div>
      </div>
    </div>
  )
}
