import React from 'react'
import Components from '../../routes'
import Footer from '../footer/footer'
import Header from '../Header/header'

function Layout() {
  return (
    <>

      <Header/>

      <Components/>

      <Footer/>

    </>
    
  )
}

export default Layout