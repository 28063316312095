import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import packageimg1 from '../img/service-3 1.png'
import packageimg2 from '../img/service-1 1.png'
import packageimg3 from '../img/service-2 1.png'

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./multipleSlider.css";

// import required modules
import { Navigation } from "swiper";

export default function MultipleSlider() {
  return (
    <>
      <Swiper
        slidesPerView={4}
        spaceBetween={0}
        navigation={true} modules={[Navigation]}
        className="myMultipleSwiper"
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        }}
      >
        <SwiperSlide className="MultipleSlider-container">
        <img src={packageimg1} alt=""  className="MultipleSLiderImg"/>
        </SwiperSlide>
        <SwiperSlide className="MultipleSlider-container">
        <img src={packageimg2} alt=""  className="MultipleSLiderImg"/>
        </SwiperSlide>
        <SwiperSlide className="MultipleSlider-container">
        <img src={packageimg1} alt=""  className="MultipleSLiderImg"/>
        </SwiperSlide>
        <SwiperSlide className="MultipleSlider-container">
        <img src={packageimg1} alt=""  className="MultipleSLiderImg"/>
        </SwiperSlide>
        <SwiperSlide className="MultipleSlider-container">
        <img src={packageimg3} alt=""  className="MultipleSLiderImg"/>
        </SwiperSlide>
        <SwiperSlide className="MultipleSlider-container">
        <img src={packageimg1} alt=""  className="SingleSLiderImg"/>
        </SwiperSlide>
        <SwiperSlide className="MultipleSlider-container">
        <img src={packageimg2} alt=""  className="SingleSLiderImg"/>
        </SwiperSlide>
        <SwiperSlide className="MultipleSlider-container">
        <img src={packageimg3} alt=""  className="SingleSLiderImg"/>
        </SwiperSlide>
        <SwiperSlide className="MultipleSlider-container">
        <img src={packageimg1} alt=""  className="SingleSLiderImg"/>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
