import React from 'react'
import './footer.css'

function Footer() {
  return (
    <div className='footer'>
        <b>All Rights Reserved 2022 © Bharathavarsha Parikraman</b>
    </div>
  )
}

export default Footer

