import React from 'react'
import '../topbar/topbar.css'
import image from '../topbar/Artboard_6.png'
import logo from '../topbar/Artboard_5.png'

export default function Topbar() {
  return (
    <div className='topbar'>
      <div className="topbar_wrapper">
        <div className="topbar_left">
            <span className='Logo'><img src={logo} alt="" className="topbar_logo"/></span>
        </div>
        <div className="tobar_Right">
          <h1 className='topbar_avartar_name'>Username</h1>
          <img src={image} alt="" className='topbar_avatar'/>
        </div>
      </div>
    </div>
  )
}
