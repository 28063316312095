// import Content from "../content/content";

const menuItem = [
    {
        title: 'Home',
        path:'/',
    },
    {
        title: 'Yathras',
        path:'/Packagelist'
    },
    {
        title: 'Gallery',
        path:'/gallery'
    },
    {
        title: 'Blog',
        path:'/blog'
    },
    {
        title: 'Shop',
        path:'/shop'
    },
    {
        title: 'About',
        path:'/about'
    },
    {
        title: 'Contact',
        path:'/contact'
    },
    // {
    //     title: 'Login/Signup',
    //     path:'/login'
    // }
];

export default menuItem;