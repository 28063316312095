import React, { useEffect, useState } from 'react'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

import '../userlist/userlist.css'
import { userRows } from './dummydata';
import http from '../../../axios';

export default function Userlist() {
  
  const [data,setData] = useState(userRows);

  useEffect(()=> {

    let response = http.get('admin/user/all',{
      headers:{
        'Authorization': `Bearer ${localStorage.getItem('admin_token')}`
      }
    })
    .then( response => {

      console.log(response.data.data);

      setData(response.data.data);
    });

  },[0]);

  const handleDelete = (id)=>{
    setData(data.filter((item) => item.id !== id));
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 130 },
    { field: 'username', headerName: 'User name', width: 130 },
    { field: 'email', headerName: 'Email ID', width: 130 },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      width: 90,
    },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params: GridValueGetterParams) =>
    //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
    // {
    //   field: 'username',
    //   headerName: 'Username',
    //   width: 160,
    //   valueGetter: (params: GridValueGetterParams) =>
    //   `${params.row.firstName || ''}${params.row.lastName || ''}${params.row.age || ''}`,
    // },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params)=>{
        return(
          <>
          <Link to={"/users/"+params.row.id}>
          <button className='userListEdit'>Edit</button>
          </Link>
            <DeleteOutlinedIcon className='userListDelete' onClick={()=>handleDelete(params.row.id)}/>  
          </>
        )
      }
    },
  ];
  
  return (
    <div className='userlist' style={{ height: 700, width: '70%',}}>
      <h2>User List</h2>
       <DataGrid rows={data} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[5]} checkboxSelection/>
    </div>
  )
}
