import React from 'react'
import { Rating } from 'react-simple-star-rating'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle';
import PackageSlider from '../packagelist/slider/slider';

import Header from '../../../Components/Header/header';
import '../packagelist/demo-packagelist.css'

import packageimg1 from '../packagelist/img/service-3 1.png'
import packageimg2 from '../packagelist/img/service-1 1.png'
import packageimg3 from '../packagelist/img/service-2 1.png'
import packageimg4 from '../packagelist/img/Image 2 1.png'
import http from '../../../axios';

export default function DemoPackagelist() {

  //Star Rating
  const [rating, setRating] = useState(0)
  const [packages, setPackages] = useState([]);

  useEffect(() =>{

    let response = http.get('admin/package/all')
      
      .then( response => {

        console.log('data',response.data.data);
        setPackages(response.data.data);
      });

      console.log('packages',packages);

  },[0])

  // Catch Rating value
  const handleRating = (rate: number) => {
    setRating(rate)
  }
  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  const onPointerMove = (value: number, index: number) => console.log(value, index)


  return (
    <div>
      <Header/>

      {/* Trending section */}
      {/* <section className="hero container-fluid section-margin">
        <div className="container-fluid d-flex justify-content-center align-items-center flex-column mt-5">
          <div className="container mb-5 d-flex flex-column justify-content-center align-items-center">
            <h6>Whats trending in teerthayatra</h6>
            <h2>Look At The Trending</h2>
          </div>

            <div className="d-flex container-fluid">
              <div className="col-4 d-flex">
                <div className="package-image d-flex flex-column justify-content-center">
                  <img src ={packageimg1} alt=''/>
                </div>
                <div className="package-details mr-5 py-4">
                  <h2 className="packagename">
                    The wind of kaashi
                  </h2>
                  <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                    incididunt ut laborea. 
                  </p>
                  <Rating
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    onPointerMove={onPointerMove}
                    size={27}
                  />
                  <br />
                  <button className='btn explorebtn px-4'>Explore</button>
                </div>
              </div>
              
              <div className="col-4 d-flex">
              <div className="package-image d-flex flex-column justify-content-center">
            <img src ={packageimg2} alt=''/>
          </div>
          <div className="package-details mr-5 py-4">
            <h2 className="packagename">
              The wind of kaashi
            </h2>
            <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
              incididunt ut laborea. 
            </p>
            <Rating
              onClick={handleRating}
              onPointerEnter={onPointerEnter}
              onPointerLeave={onPointerLeave}
              onPointerMove={onPointerMove}
              size={27}
            />
            <br />
            <button className='btn explorebtn px-4'>Explore</button>
          </div>
              </div>

              <div className="col-4 d-flex">
              <div className="package-image d-flex flex-column justify-content-center">
            <img src ={packageimg3} alt=''/>
          </div>
          <div className="package-details mr-5 py-4">
            <h2 className="packagename">
              The wind of kaashi
            </h2>
            <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
              incididunt ut laborea. 
            </p>
            <Rating
              onClick={handleRating}
              onPointerEnter={onPointerEnter}
              onPointerLeave={onPointerLeave}
              onPointerMove={onPointerMove}
              size={27}
            />
            <br />
            <button className='btn explorebtn px-4'>Explore</button>
          </div>
              </div>
            </div>

          </div>
      </section> */}

      {/* Top Destination */}
      <section className='destination section-margin'>
        <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
          <div className="container mx-auto d-flex flex-column justify-content-center align-items-center">
            <h6>Whats trending in teerthayatra</h6>
            <h2>Top Destination For You</h2>
          </div>

            <div className="col-lg-12 col-gap d-flex flex-wrap justify-content-center align-items-center">

              {
                packages.map((item,index) => {
                  return(
                    <div key={index} className=" d-flex flex-column justify-content-center align-items-center">
                      <div className="destinationPackageImage">
                        <img src ={packageimg1} alt=''/>
                      </div>
                      <div className="destinationPackageDetails">
                        <div className="container">
                          <h2 className="packagename">
                              {item.pkg_name}
                          </h2>
                          <p>{item.description} 
                          </p>
                          <Rating
                            onClick={handleRating}
                            onPointerEnter={onPointerEnter}
                            onPointerLeave={onPointerLeave}
                            onPointerMove={onPointerMove}
                            size={27}
                          />
                          <br />
                          <Link to='/View-package-details' state={{item:item}}><button className='btn explorebtn px-4'>Explore</button></Link>
                        </div>
                      </div>
                    </div>
                  )
                })
              }

              {/* <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="destinationPackageImage">
                  <img src ={packageimg2} alt=''/>
                </div>
                <div className="destinationPackageDetails">
                  <div className="container">
                    <h2 className="packagename">
                        The wind of kaashi
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                      incididunt ut laborea. 
                    </p>
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={27}
                    />
                    <br />
                    <button className='btn explorebtn px-4'>Explore</button>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="destinationPackageImage">
                  <img src ={packageimg3} alt=''/>
                </div>
                <div className="destinationPackageDetails">
                  <div className="container">
                    <h2 className="packagename">
                        The wind of kaashi
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                      incididunt ut laborea. 
                    </p>
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={27}
                    />
                    <br />
                    <button className='btn explorebtn px-4'>Explore</button>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="destinationPackageImage">
                  <img src ={packageimg1} alt=''/>
                </div>
                <div className="destinationPackageDetails">
                  <div className="container">
                    <h2 className="packagename">
                        The wind of kaashi
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                      incididunt ut laborea. 
                    </p>
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={27}
                    />
                    <br />
                    <button className='btn explorebtn px-4'>Explore</button>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="destinationPackageImage">
                  <img src ={packageimg2} alt=''/>
                </div>
                <div className="destinationPackageDetails">
                  <div className="container">
                    <h2 className="packagename">
                        The wind of kaashi
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                      incididunt ut laborea. 
                    </p>
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={27}
                    />
                    <br />
                    <button className='btn explorebtn px-4'>Explore</button>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="destinationPackageImage">
                  <img src ={packageimg3} alt=''/>
                </div>
                <div className="destinationPackageDetails">
                  <div className="container">
                    <h2 className="packagename">
                        The wind of kaashi
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                      incididunt ut laborea. 
                    </p>
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={27}
                    />
                    <br />
                    <button className='btn explorebtn px-4'>Explore</button>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="destinationPackageImage">
                  <img src ={packageimg1} alt=''/>
                </div>
                <div className="destinationPackageDetails">
                  <div className="container">
                    <h2 className="packagename">
                        The wind of kaashi
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                      incididunt ut laborea. 
                    </p>
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={27}
                    />
                    <br />
                    <button className='btn explorebtn px-4'>Explore</button>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="destinationPackageImage">
                  <img src ={packageimg2} alt=''/>
                </div>
                <div className="destinationPackageDetails">
                  <div className="container">
                    <h2 className="packagename">
                        The wind of kaashi
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                      incididunt ut laborea. 
                    </p>
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={27}
                    />
                    <br />
                    <button className='btn explorebtn px-4'>Explore</button>
                  </div>
                </div>
              </div> */}

            </div>
        </div>
      </section>

      {/* Slider */}
      <section className='section-margin'>
        <div className="packageList-slider-container d-flex flex-wrap">
          <div className="packageList-slider col-lg-12 d-flex">
            <div className="packagelistLeftImage col-6 d-flex justify-content-center">
              <img src={packageimg4} alt="" />
            </div>
            <div className="packagelistRightSlider col-6 d-flex">
              <PackageSlider/>
            </div>
          </div>
        </div>
      </section>

      {/* Category */}
      
      <section className="category section-margin">
      <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
          <div className="container mx-auto d-flex flex-column justify-content-center align-items-center">
            <h6>Whats trending in teerthayatra</h6>
            <h2>Category heading Here</h2>
          </div>

            <div className="col-lg-12 col-gap d-flex flex-wrap justify-content-center align-items-center">

              <div className=" d-flex flex-column justify-content-center align-items-center">
                <div className="destinationPackageImage">
                  <img src ={packageimg1} alt=''/>
                </div>
                <div className="destinationPackageDetails">
                  <div className="container">
                    <h2 className="packagename">
                        The wind of kaashi
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                      incididunt ut laborea. 
                    </p>
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={27}
                      /* Available Props */
                    />
                    <br />
                    <button className='btn explorebtn px-4'>Explore</button>
                  </div>
                </div>
              </div>

              <div className=" d-flex flex-column justify-content-center align-items-center">
                <div className="destinationPackageImage">
                  <img src ={packageimg2} alt=''/>
                </div>
                <div className="destinationPackageDetails">
                  <div className="container">
                    <h2 className="packagename">
                        The wind of kaashi
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                      incididunt ut laborea. 
                    </p>
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={27}
                      /* Available Props */
                    />
                    <br />
                    <button className='btn explorebtn px-4'>Explore</button>
                  </div>
                </div>
              </div>

              <div className=" d-flex flex-column justify-content-center align-items-center">
                <div className="destinationPackageImage">
                  <img src ={packageimg3} alt=''/>
                </div>
                <div className="destinationPackageDetails">
                  <div className="container">
                    <h2 className="packagename">
                        The wind of kaashi
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                      incididunt ut laborea. 
                    </p>
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={27}
                      /* Available Props */
                    />
                    <br />
                    <button className='btn explorebtn px-4'>Explore</button>
                  </div>
                </div>
              </div>

              <div className=" d-flex flex-column justify-content-center align-items-center">
                <div className="destinationPackageImage">
                  <img src ={packageimg1} alt=''/>
                </div>
                <div className="destinationPackageDetails">
                  <div className="container">
                    <h2 className="packagename">
                        The wind of kaashi
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                      incididunt ut laborea. 
                    </p>
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={27}
                      /* Available Props */
                    />
                    <br />
                    <button className='btn explorebtn px-4'>Explore</button>
                  </div>
                </div>
              </div>

              <div className=" d-flex flex-column justify-content-center align-items-center">
                <div className="destinationPackageImage">
                  <img src ={packageimg2} alt=''/>
                </div>
                <div className="destinationPackageDetails">
                  <div className="container">
                    <h2 className="packagename">
                        The wind of kaashi
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                      incididunt ut laborea. 
                    </p>
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={27}
                      /* Available Props */
                    />
                    <br />
                    <button className='btn explorebtn px-4'>Explore</button>
                  </div>
                </div>
              </div>

              <div className=" d-flex flex-column justify-content-center align-items-center">
                <div className="destinationPackageImage">
                  <img src ={packageimg3} alt=''/>
                </div>
                <div className="destinationPackageDetails">
                  <div className="container">
                    <h2 className="packagename">
                        The wind of kaashi
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                      incididunt ut laborea. 
                    </p>
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={27}
                      /* Available Props */
                    />
                    <br />
                    <button className='btn explorebtn px-4'>Explore</button>
                  </div>
                </div>
              </div>

              <div className=" d-flex flex-column justify-content-center align-items-center">
                <div className="destinationPackageImage">
                  <img src ={packageimg1} alt=''/>
                </div>
                <div className="destinationPackageDetails">
                  <div className="container">
                    <h2 className="packagename">
                        The wind of kaashi
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                      incididunt ut laborea. 
                    </p>
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={27}
                      /* Available Props */
                    />
                    <br />
                    <button className='btn explorebtn px-4'>Explore</button>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="destinationPackageImage">
                  <img src ={packageimg2} alt=''/>
                </div>
                <div className="destinationPackageDetails">
                  <div className="container">
                    <h2 className="packagename">
                        The wind of kaashi
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur treuea tempor
                      incididunt ut laborea. 
                    </p>
                    <Rating
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      size={27}
                      /* Available Props */
                    />
                    <br />
                    <button className='btn explorebtn px-4'>Explore</button>
                  </div>
                </div>
              </div>
              

            </div>
          
        </div>
      </section>

    </div>
  )
}
