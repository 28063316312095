import React from 'react'
import Table from 'react-bootstrap/Table';

function YathraPayments() {
  return (

    <div>
        <h3>Yathra Payments</h3>
        <br />
        <Table striped bordered hover style={{width: "calc(100% - 100px)"}}>
        <thead>
            <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Username</th>
            <th>Payment</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>@mdo</td>
            </tr>
            <tr>
            <td>2</td>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@fat</td>
            <td>@fat</td>
            </tr>
            <tr>
            <td>3</td>
            <td>Larry the Bird</td>
            <td>@twitter</td>
            <td>@twitter</td>
            <td>@twitter</td>
            </tr>
        </tbody>
        </Table>
    </div>

  )
}

export default YathraPayments