
import React from 'react'
import Topbar from '../components/topbar/topbar'
import Sidebar from '../components/sidebar/sidebar'
import '../Dashboard/Dashboard.css'

import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Userlist from '../pages/userlist/userlist'
import User from '../pages/user/user'
import Components from '../../routes'
import Charts from '../pages/home/charts/charts'
import Yathrapackages from '../pages/yathras/yathra packages/yathrapackages';
import Yathrapackagesnew from '../pages/yathras/yathra packages/yathrapackagesnew';
import Hotels from '../pages/hotels/hotels';
import LiveBooking from '../pages/bookings/liveBooking';
import BookingHistory from '../pages/bookings/bookingHistory';
import YathraPayments from '../pages/bookings/yathraPayments';
import ManagePackages from '../pages/yathras/yathra packages/managePackages';
import Orders from '../pages/shop/orders';
import ShopPayments from '../pages/shop/payments';
import Inventory from '../pages/shop/inventory';
import AdminProtected from '../../adminProtected';

function Dashboard() {
  return (
    <>
      <Topbar/>
      <div className="fluid-container">

        <div className='wrapper'>

          <div className='col-sidebar'>
            <Sidebar/>
          </div>

          <div className='col-main'>
            <AdminProtected/>
          </div>
            
        </div>

        
          {/* <Route path=":resourceId" element={<Resource />} /> */}
        {/* </Route> */}

      </div>
    </>
  )
}

export default Dashboard;
