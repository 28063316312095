import React from 'react'
import './homepage-form.css'

function Homepageform() {
  return (
    <div className='form'>

        <table>
        <tbody>
            <tr>
                <td><label>Yatra To</label></td>
                <td>
                    <select placeholder='Choose Destination'>
                        <option>Choose Destination</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td><label>To</label></td>
                <td>
                    <input type='date' placeholder='Choose Destination'></input>
                </td>
            </tr>
            <tr>
                <td><label>From</label></td>
                <td>
                    <input type='date' placeholder='Choose Destination'></input>
                </td>
            </tr>

            {/* submit button */}
            
            <tr>                
                <td align='left'>
                    <input type='submit'></input>
                </td>
                <td></td>
            </tr>
        </tbody>
        </table>
       


        {/* <span>
            <label>Yatra To</label><select placeholder='Choose Destination'>
                <option>Choose Destination</option>
            </select>
        </span>
        <span>
            <label>From</label><input type='date' placeholder='Choose Destination'></input>
        </span>
        <span>
            <label>To</label><input type='date' placeholder='Choose Destination'></input>
        </span>
        <span>
            <input type='submit'></input>
        </span> */}
        
        
      
    </div>
  )
}

export default Homepageform